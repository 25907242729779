import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { userChats } from "../../api/ChatRequest";
import { io } from "socket.io-client";
import Contract from "../../components/contract/Contract";

const ContractList = () => {
  const { user } = useSelector((state) => state.authReducer.authData);
  const socket = useRef();
  const [chats, setChats] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [sendMessage, setSendMessage] = useState(null);
  const [receivedMessage, setReceivedMessage] = useState(null);
  useEffect(() => {
    const getChats = async () => {
      try {
        const { data } = await userChats(user._id);
        setChats(data);
      } catch (error) {
        console.log(error);
      }
    };
    getChats();
  }, [user._id]);

  useEffect(() => {
    socket.current = io("https://chatappapi.pino10.shop", {
      transports: ["websocket"],
      upgrade: false,
    });
    // socket.current = io("https://deft-paprenjak-f681e6.netlify.app", {
    //   withCredentials: true,
    // });
    socket.current.emit("new-user-add", user._id);
    socket.current.on("get-users", (users) => {
      setOnlineUsers(users);
    });
  }, [user]);

  // Send Message to socket server
  useEffect(() => {
    if (sendMessage !== null) {
      socket.current.emit("send-message", sendMessage);
    }
  }, [sendMessage]);

  // Get the message from socket server
  useEffect(() => {
    socket.current.on("recieve-message", (data) => {
      console.log(data);
      setReceivedMessage(data);
    });
  }, []);

  const checkOnlineStatus = (chat) => {
    const chatMember = chat.members.find((member) => member !== user._id);
    const online = onlineUsers.find((user) => user.userId === chatMember);
    return online ? true : false;
  };
  return (
    <div className="row">
      <div className="col-md-12 mt-5">
        {chats.map((chat) => (
          <div
            onClick={() => {
              setCurrentChat(chat);
            }}
            key={chat._id}
          >
            <Contract
              data={chat}
              currentUser={user._id}
              online={checkOnlineStatus(chat)}
            />
          </div>
        ))}
      </div>
      {/* <div className="col-md-3 home_main_sm">
        <RightSide />
      </div> */}
    </div>
  );
};

export default ContractList;
