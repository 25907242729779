import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { userChats } from "../../api/ChatRequest";
import { getAllUser } from "../../api/UserRequest";
import MessengerAdd from "../User/MessengerAdd";
const AddMessengers = ({ location }) => {
  const { user } = useSelector((state) => state.authReducer.authData);
  const [persons, setPersons] = useState([]);
  const [member, setMember] = useState([]);

  useEffect(() => {
    const fetchPersons = async () => {
      const { data } = await getAllUser();
      setPersons(data);
    };
    fetchPersons();
  }, [user]);

  useEffect(() => {
    const fetchPersons = async () => {
      const { data } = await userChats(user._id);
      setMember(data);
    };
    fetchPersons();
  }, [user]);

  const allMembersId = persons.map((pd) => pd._id);

  const newMemberId = member.map((pd) => pd.members[1]);
  const newMemberId2 = member.map((pd) => pd.members[0]);

  const newMembersId = allMembersId.filter((element) =>
    newMemberId.includes(element)
  );

  const newMembersDetails = newMembersId.map((id) =>
    persons.find((el) => el._id == id)
  );

  const result = persons.filter(function (o1) {
    return !newMembersDetails.some(function (o2) {
      return o1._id == o2._id;
    });
  });

  const newMembersId3 = result.filter((element) =>
    newMemberId2.includes(element._id)
  );

  const result2 = result.filter(function (o1) {
    return !newMembersId3.some(function (o2) {
      return o1._id == o2._id;
    });
  });

  return (
    <div>
      <h3>People you may know</h3>
      <Grid container spacing={2}>
        {result2.map((message, id) => {
          if (message._id !== user._id && newMembersId3)
            return <MessengerAdd message={message} key={id} />;
        })}
      </Grid>
    </div>
  );
};

export default AddMessengers;
