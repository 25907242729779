import React from "react";
import "./profileCard.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const ProfileCard = ({ location }) => {
  const { user } = useSelector((state) => state.authReducer.authData);
  const serverPublic = process.env.REACT_APP_PUBLIC_FOLDER;
  const posts = useSelector((state) => state.postReducer.posts);
  return (
    <>
      <div className="ProfileCard">
        <div className="ProfileImages">
          <img
            src={
              user.coverPicture
                ? user.coverPicture
                : "https://i.ibb.co/5kywKfd/user-removebg-preview.png"
            }
            alt="CoverImage"
          />
          <img
            src={
              user.profilePicture
                ? user.profilePicture
                : "https://i.ibb.co/5kywKfd/user-removebg-preview.png"
            }
            alt="ProfileImage"
          />
        </div>
        <div className="ProfileName">
          <span>
            {user.firstname} {user.lastname}
          </span>
          <span>{user.worksAt ? user.worksAt : "Write about yourself"}</span>
        </div>
        <div className="followStatus">
          <hr />
          <div>
            <div className="follow">
              <span>{user.followers.length}</span>
              <span>Followers</span>
            </div>
            <div className="vl"></div>
            <div className="follow">
              <span>{user.following.length}</span>
              <span>Following</span>
            </div>
            {/* for profilepage */}
            {location === "profilePage" && (
              <>
                <div className="vl"></div>
                <div className="follow">
                  <span>
                    {posts.filter((post) => post.userId === user._id).length}
                  </span>
                  <span>Posts</span>
                </div>{" "}
              </>
            )}
          </div>
          <hr />
        </div>
        {location === "profilePage" ? (
          ""
        ) : (
          <span>
            <Link
              to={`/profile/${user._id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              My Profile
            </Link>
          </span>
        )}
      </div>
    </>
  );
};

export default ProfileCard;
