import { Box, Card } from "@mantine/core";
import { CardActions, CardContent, CardMedia, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { followUser, unfollowUser } from "../../actions/userAction";

const User = ({ person }) => {
  const publicFolder = process.env.REACT_APP_PUBLIC_FOLDER;
  const { user } = useSelector((state) => state.authReducer.authData);
  const dispatch = useDispatch();

  const [following, setFollowing] = useState(
    person.followers.includes(user._id)
  );
  const handleFollow = () => {
    following
      ? dispatch(unfollowUser(person._id, user))
      : dispatch(followUser(person._id, user));
    setFollowing((prev) => !prev);
  };

  return (
    <>
      <Box>
        <Card sx={{ maxWidth: 345 }}>
          <CardMedia
            component="img"
            image={
              person.profilePicture
                ? person.profilePicture
                : "https://i.ibb.co/5kywKfd/user-removebg-preview.png"
            }
            alt="green iguana"
            className="img-fluid"
            style={{ height: 260 }}
          />
          <CardContent sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              gutterBottom
              variant="topic"
              component="div"
              className="name_sm"
              sx={{ textTransform: "capitalize" }}
            >
              {person.firstname} {person.lastname}
            </Typography>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <button
              className={
                following
                  ? "button fc-button UnfollowButton"
                  : "button fc-button"
              }
              onClick={handleFollow}
            >
              {following ? "Unfollow" : "Follow"}
            </button>
          </CardActions>
        </Card>
      </Box>
    </>
  );
};

export default User;
